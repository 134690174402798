import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {KeycloakBearerInterceptor, KeycloakService} from 'keycloak-angular';

@Injectable()
export class CustomKeycloakBearerInterceptor extends KeycloakBearerInterceptor  implements HttpInterceptor{

  constructor(private ks: KeycloakService) {
    super(ks);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Controlla se la rotta inizia con /public

    if (req.url.startsWith('/public')) {
      return next.handle(req); // Non applicare il token
    }

    return super.intercept(req, next);
  }
}
