import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('-');
      if (dateParts.length === 1 && Number.isInteger(dateParts[0])) {
        return {day: +dateParts[0], month: null, year: null};
      } else if (dateParts.length === 2 && Number.isInteger(dateParts[0]) && Number.isInteger(dateParts[1])) {
        return {day: +dateParts[0], month: +dateParts[1], year: null};
      } else if (dateParts.length === 3 && Number.isInteger(dateParts[0]) && Number.isInteger(dateParts[1]) && Number.isInteger(dateParts[2])) {
        return {day: +dateParts[0], month: +dateParts[1], year: +dateParts[2]};
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
      `${Number.isInteger(date.day) ? (date.day+'').padStart(2, '0') : ''}-${Number.isInteger(date.month) ? (date.month+'').padStart(2, '0') : ''}-${date.year}` :
      '';
  }
}
