import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {split} from "@apollo/client";
import {getMainDefinition} from "@apollo/client/utilities";
import {ApolloClientOptions, InMemoryCache} from "@apollo/client/core";
import {HttpLink} from "apollo-angular/http";
import {AuthService} from "./service/auth.service";
import {environment} from "../environments/environment";
import {APOLLO_FLAGS, APOLLO_OPTIONS, ApolloModule} from "apollo-angular";
import {LayoutComponent} from './layout/layout.component';
import {HeaderComponent} from './layout/header/header.component';
import {MenuComponent} from './layout/menu/menu.component';
import {MatIconModule} from "@angular/material/icon";
import {IconsModule} from "./icons/icons.module";
import {AvatarComponent} from './layout/avatar/avatar.component';
import {MatMenuModule} from '@angular/material/menu';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatFormFieldModule, MatSuffix} from "@angular/material/form-field";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {TitleCasePipe, UpperCasePipe} from "@angular/common";
import {ToastrModule} from "ngx-toastr";
import {KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakEventType, KeycloakService} from "keycloak-angular";
import {NgxEchartsModule} from "ngx-echarts";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgEventBus} from "ng-event-bus";
import {LogoComponent} from "./layout/logo/logo.component";
import {ComponentsModule} from "./components/components.module";
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbModule,
  NgbNavModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgbDateCustomParserFormatter} from "./components/custom-date-parser";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatSliderModule} from '@angular/material/slider';
import {firstValueFrom, map} from "rxjs";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from 'graphql-ws';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import {MatBadge} from "@angular/material/badge";
import {CustomKeycloakBearerInterceptor} from "./service/CustomKeycloakBearerInterceptor ";

export function initializeKeycloak(keycloak: KeycloakService) {
  return () => {
    const token = localStorage.getItem('kc_token');
    const refreshToken = localStorage.getItem('kc_refresh_token');
    const idToken = localStorage.getItem('kc_id_token');


    return keycloak.init({
      bearerPrefix: 'Bearer',
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId,
      },
      initOptions: {
        useNonce: true,
        flow: 'standard',
        onLoad: environment.keycloak.onLoad,
        adapter: 'default',
        redirectUri: window.location.origin + '/port',
        checkLoginIframe: environment.keycloak.checkLoginIframe,
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        token: token,
        refreshToken: refreshToken,
        idToken: idToken,

      },

    }).then(x => {
      if (x) {
        const idToken = keycloak.getKeycloakInstance().idToken;
        const refreshToken = keycloak.getKeycloakInstance().refreshToken;
        const token = keycloak.getKeycloakInstance().token;
        localStorage.setItem('kc_token',token);
        localStorage.setItem('kc_refresh_token',refreshToken);
        localStorage.setItem('kc_id_token',idToken);


      }
      return x
    });
  };
}


export function createApollo(httpLink: HttpLink, authService: AuthService): ApolloClientOptions<any> {
  const http = httpLink.create({
    uri: environment.graphql_endpoint,
  })
  const ws: GraphQLWsLink = new GraphQLWsLink(createClient({
    url: environment.ws_endpoint,
    connectionParams: () => firstValueFrom(authService.getJwtToken().pipe(
      map(x => {
        return {
          Authorization: 'Bearer ' + x
        }
      })
    )),
    // retryWait: ()=> ,
    shouldRetry: () => true
  }));


  const link = split(
    ({query}) => {
      const data = getMainDefinition(query);
      return (
        data.kind === 'OperationDefinition' && data.operation === 'subscription'
      );
    },
    ws,
    http
  )

  return {
    cache: new InMemoryCache(),
    link: link
  }
}


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    LogoComponent,
    MenuComponent,
    AvatarComponent,
    PublicLayoutComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    IconsModule,
    MatIconModule,
    MatMenuModule,
    MatSuffix,
    ApolloModule,
    MatNativeDateModule,
    FontAwesomeModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    ComponentsModule,
    NgbModule,
    MatPaginatorModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    NgbNavModule,
    NgbDatepickerModule,
    NgbTypeaheadModule,
    NgSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    MatBadge,
  ],
  providers: [
    AuthService,
    TitleCasePipe,
    UpperCasePipe,
    MatNativeDateModule,
    // {provide: FormioAppConfig, useValue: AppConfig},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomKeycloakBearerInterceptor,
      multi: true
    },
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: true // enable it here
      }
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService],
    },
     {
       provide: APP_INITIALIZER,
       useFactory: initializeKeycloak,
       multi: true,
      deps: [KeycloakService]
     },
    NgEventBus,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},


  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
