import {Apollo, gql, MutationResult} from "apollo-angular";
import {Injectable, OnDestroy} from "@angular/core";
import {combineLatest, filter, map, Observable, of, Subject, takeUntil, tap} from "rxjs";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";
import {BoatDTO, NotificationDTO, ShipYardDataDTO} from "./dto";
import {ATTACHMENT_FIELD} from "./attachment.service";

export const NOTIFICATION_FIELD = gql`
  fragment NOTIFICATION_FIELD on NotificationDTO {
    id
    message
    receiverUserId
    status
    senderUserId
    title
  }
`;


const getAll = gql`
  ${NOTIFICATION_FIELD}

  query GetAllNotifications($pageNumber: Int, $pageDim: Int, $where:String) {
    notification (pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "id,desc") {
      ...NOTIFICATION_FIELD

    }
  }
`;

const listenOn = gql`

  subscription NotificationSubscription($pageNumber: Int, $pageDim: Int, $where:String) {
    notification(pageNum: $pageNumber, pageDim: $pageDim, where: $where) {
      id
      title
      message
      __typename
    }
  }
`;

const getById = gql`
  ${NOTIFICATION_FIELD}

  query GetNotificationById($id: ID!) {
    notification_by_pk(id: $id) {
      ...NOTIFICATION_FIELD

    }
  }`;


const count = gql`query CountNotification( $where: String ) {
  notification_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;

const update = gql`
  ${NOTIFICATION_FIELD}

  mutation NotificationUpdate($id: ID!, $dto: NotificationInput!) {
    notification_update_one(id: $id, body: $dto){
      ...NOTIFICATION_FIELD

    }

  }`

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) {

  }

  listenOn(page: number = 0, pageSize: number = 10, filters?: BoolOperation) {
    // return of (null);
    return this.apollo.subscribe({
      query: listenOn,
      variables: {
        pageNumber: page,
        pageDim: pageSize,
        where: ExpressionBuilder.toGql(filters),
      },

    }).pipe(
      map(x => {
          // @ts-ignore
          const data = x.data?.['notification'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation): Observable<{
    data: NotificationDTO[],
    totalRows: number
  }> {

    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',
      }).valueChanges.pipe(filter(c => !c.loading));
    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      tap(x => console.log(x)),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['notification_aggregate']
        // @ts-ignore
        const data = x[0].data?.['notification']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number, includes?: { port: boolean }): Observable<BoatDTO> {
    const inc = {
      ...{port: false},
      ...includes
    }
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
        includePort: inc.port
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['notification_by_pk'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }

  count(filters?: BoolOperation): Observable<number> {
    return this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(
        filter(c => !c.loading),
        map(x => {
            // @ts-ignore
            const aggRes = x.data['notification_aggregate']
            return aggRes.count
          }
        )
      )
  }

  update(id: number, dto: NotificationDTO): Observable<NotificationDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.notification_update_one));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
