import {Component} from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss'
})
export class AvatarComponent {
  username: Observable<string>;

  constructor(private authService: AuthService) {
    this.username = this.authService.getUsername()
  }

  logout() {
    this.authService.logout()
  }
}
