<div [matMenuTriggerFor]="menu" class="flex flex-row space-x-4 text-gray-700 cursor-pointer"
     style="border: 1px solid var(--light-blue); border-radius: 10px">
  <div class="avatar">
    <img src="assets/images/avatar.svg">
  </div>
  <div class="flex flex-col space-y-1 justify-center pr-3 text-xs">
    <div><span class="font-bold">{{ username|async |titlecase }}</span></div>
    <div class="text-gray-500 ">Gestisci account</div>
  </div>
  <mat-menu #menu="matMenu">
    <button class="flex space-x-2" mat-menu-item>
      <span class="text-sm">Dettagli account</span>

      <mat-icon class="scale-75" matIconSuffix svgIcon="mat_outline:badge"></mat-icon>
    </button>
    <button (click)="logout()" class="flex flex-row space-x-2" mat-menu-item>
      <span class="text-sm">Logout</span>
      <mat-icon class="scale-75" matIconSuffix svgIcon="mat_outline:exit_to_app"></mat-icon>
    </button>
  </mat-menu>

</div>
