import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientModule} from "@angular/common/http";
import {LayoutComponent} from "./layout/layout.component";
import {AuthGuard} from "./guard/AuthGuard";
import {PublicLayoutComponent} from "./public-layout/public-layout.component";


const routes: Routes = [

  {
    path: 'public',
    pathMatch: 'prefix',
    component: PublicLayoutComponent,
    children: [
      {path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule)},

    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/port'
  },
  {
    path: '',
    component: LayoutComponent,
    //canMatch: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
      {path: 'boats', loadChildren: () => import('./boats/boats.module').then(m => m.BoatsModule)},
      {path: 'reservations', loadChildren: () => import('./reservations/reservations.module').then(m => m.ReservationsModule)},
      {path: 'port', loadChildren: () => import('./port/port.module').then(m => m.PortModule)},
      {path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)},
    ],
  },

];
const routerConfig: ExtraOptions = {
  // preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, routerConfig),

  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
