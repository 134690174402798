import {Component, OnDestroy, OnInit} from '@angular/core';
import {faBell, faEyeSlash, faSearch, faUpload, faUserAlt} from '@fortawesome/free-solid-svg-icons';
import {NgEventBus} from "ng-event-bus";
import {NotificationService} from "../../service/notification.service";
import {exhaustMap, map, mergeMap, ReplaySubject, takeUntil, tap, withLatestFrom} from "rxjs";
import {ExpressionBuilder} from "../../service/ExpressionBuilder";
import {AuthService} from "../../service/auth.service";
import {catchError} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {NotificationDTO, NotificatonStatus} from "../../service/dto";

export class TitleEvent {
  pageTitle: string = 'Dashboard'
  navBar?: { name: string, url?: string, key?: any }[]
  backUrl?: string
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  title: TitleEvent = {pageTitle: '', navBar: []}
  notifications$ = new ReplaySubject()
  destroy$ = new ReplaySubject()
  notifications = {
    total: 0,
    data: []
  };
  protected readonly faUserAlt = faUserAlt;
  protected readonly faBell = faBell;
  protected readonly faSearch = faSearch;

  constructor(private eventBus: NgEventBus,
              private authService: AuthService,
              private toastService: ToastrService,
              private notificationService: NotificationService) {
    eventBus.on("page:title").subscribe(x => this.title = x.data as TitleEvent)
    this.notifications$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(authService.getUserId()),
      map(x => {
        const userId = x[1]

        const exp = ExpressionBuilder.getBuilder().root().eq('status', 'TO_READ');
        const or = ExpressionBuilder.getBuilder().or().isNull('receiverUserId').eq('receiverUserId', userId)
        exp.append(or)
        return exp
      }),
      exhaustMap(x => this.notificationService.listenOn(0, 1, x).pipe(
          catchError(e => {
            this.toastService.error("Errore nel recupero dai dati")
            throw e
          }),
          mergeMap(() => this.notificationService.getAll(0, 10, x).pipe(
              tap(x => {
                this.notifications.total = x.totalRows
                this.notifications.data = x.data
              })
            )
          )
        )
      )
    ).subscribe()

  }

  ngOnInit(): void {
    this.refresh()
  }

  refresh() {
    this.notifications$.next(null)
  }

  ngOnDestroy(): void {
    this.destroy$.next(null)
  }

  protected readonly faUpload = faUpload;

  setNotificationRead(n: NotificationDTO) {
    n.status = NotificatonStatus.READ
    this.notificationService.update(n.id, n).subscribe()

  }

  protected readonly faEyeSlash = faEyeSlash;
}
