import {Component} from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor() {
    // const test  = kc.isUserInRole("operator")
    // console.log("Operator?", test)
    // this.kc.getToken().then(token => {
    //   const decoded = jwtDecode(token);
    //   console.log("decoded", decoded['auth_claims'])
    // })

  }

}
