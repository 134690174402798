import {Component} from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {Observable, tap} from "rxjs";

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss'
})
export class LogoComponent {
  tenantDisplayName: Observable<string>
  constructor(private authService: AuthService) {
    this.tenantDisplayName = this.authService.getTenantDisplayName().pipe(
      tap(x=> console.log("XXXX", x))
    )
  }
}
