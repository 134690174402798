<div
  class="w-full flex flex-row justify-between  mb-8   p-2 shadow sticky backdrop-saturate-200 backdrop-blur-2xl bg-white/80 rounded-lg ">
  <div class=" flex  items-center" [class.ml-0]="title.backUrl" [class.ml-6]="!title.backUrl">
    <!--    <div class="">-->
    <!--      <span *ngFor="let nav of title.navBar; let i = index"-->
    <!--            [routerLink]="nav.url ?? null"-->
    <!--            class="text-sm"-->
    <!--            [class.cursor-pointer]="nav.url">-->
    <!--        {{ nav.name }}-->
    <!--        <span > / </span>-->

    <!--      </span>-->
    <!--    </div>-->
    <div *ngIf="title.backUrl">
      <button mat-icon-button class="scale-75 mr-0" matTooltip="Pagina precedente" [routerLink]="title.backUrl">
        <mat-icon svgIcon="mat_outline:arrow_back"></mat-icon>
      </button>
    </div>
    <h4 class="mb-0 font-bold capitalize text-xl ">
      {{ title.pageTitle }}
    </h4>
  </div>
  <div class="flex space-x-3 items-center  text-sm mr-6">
    <div class="relative flex flex-wrap items-stretch  transition-all rounded-lg ease-soft">
      <span
        class="text-sm ease-soft leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
              <fa-icon [icon]="faSearch" class="mr-1"></fa-icon>

      </span>
      <input
        class="pl-9 h-[47px] text-sm focus:shadow-soft-primary-outline ease-soft w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500   focus:border-cyan-300 focus:outline-1 focus:outline-cyan-500  focus:transition-shadow"
        placeholder="Ricerca globale ..."
        type="text">
    </div>
    <app-avatar></app-avatar>

    <ul class="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full space-x-3 font-bold mr-6">

      <li>
        <button mat-icon-button [matMenuTriggerFor]="notMenu">
          <div [matBadge]="notifications.total" matBadgeColor="warn" [matBadgeHidden]="notifications.total <= 0"
               matBadgeSize="small">
            <fa-icon [icon]="faBell" class="cursor-pointer"></fa-icon>
          </div>
        </button>
        <mat-menu #notMenu="matMenu" class="max-h-60 overflow-y-auto">
          <div class="p-2 ">
            <ng-container *ngFor="let n of notifications.data">
              <div class="space-y-2 px-2 rounded bg-gray-100 flex items-center mb-2 border border-gray-200 border-solid	">
                <div class="flex-grow">
                  <div class="text-sm font-bold">{{ n.title| titlecase }}</div>
                  <div class="text-sm truncate">{{ n.message }}</div>
                </div>
                <div>
                  <button mat-icon-button matTooltip="Imposta come letto" class="scale-75 " (click)="setNotificationRead(n)">
                    <fa-icon [icon]="faEyeSlash" class="mr-1"></fa-icon>
                  </button>
                </div>
              </div>
            </ng-container>

          </div>

        </mat-menu>
      </li>
    </ul>
  </div>
</div>
